// VOXExporter.js
import React from 'react';

const VOXExporterPage = () => {
  return (
    <div>
      <h1>VOXExporter</h1>
    </div>
  );
};

export default VOXExporterPage;
