const projects = [
  {
    title: "SILT Middelkerke",
    description: "Middelkerke, Belgium",
    mediaType: "image",
    mediaSrc: "/projects/SILTMiddelkerke/images/AllOutput.png",
    targetUrl: "/SILTMiddelkerke",
    categories: ["Computational Design"],
  },
  {
    title: "Four Seasons Facade",
    description: "Dubai,UAE",
    mediaType: "image",
    mediaSrc:
      "/projects/Four Seasons Facade System/images/diagram 11-Photoshopped.png",
    targetUrl: "/FourSeasonsFacade",
    categories: ["Architecture"],
    invert: true,
  },
  {
    title: "ECHO",
    description: "Sinop,Turkey",
    mediaType: "image",
    mediaSrc: "/projects/ECHO/images/Arch System-logo.png",
    targetUrl: "/ECHO",
    categories: ["Architecture"],
  },
 
  {
    title: "Cliff Youth Center",
    description: "Antalya,Turkey",
    mediaType: "image",
    mediaSrc: "/projects/Cliff Youth Center/images/ArchSystemIcon.png",
    targetUrl: "/CliffYouthCenter",
    categories: ["Architecture"],
  },
 
  {
    title: "Cowes Week Pavilion",
    description: "Cowes, United Kingdom",
    mediaType: "image",
    mediaSrc: "/projects/Cowes Week Pavilion/images/robot 2.png",
    targetUrl: "CowesWeekPavilion",
    categories: ["Computational Design", "Architecture"],
  },
  {
    title: "Algorithm for Local Facade Geometries",
    description: "GENx Facade Solution",
    mediaType: "image",
    mediaSrc: "/projects/GENx Facade Solution/images/combined.jpg",
    targetUrl: "/GENxFacadeSolution",
    categories: ["Computational Design", "Software Development"],
    invert: true,
  },

  {
    title: "WEAVE",
    description: "The Extinction Museum Competition ",
    mediaType: "image",
    mediaSrc: "/projects/WEAVE/images/exploded.jpg",
    targetUrl: "/WEAVE",
    categories: ["Architecture"],
    invert: true,
  },
 
 
];
export default projects;
